 
.project-ref-detect {
    width: 100%;
    height: 525px;
    margin-bottom: 25px;
}
.project-container {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    width: 90%;
    min-width: 325px;
    max-width: 900px;
    background-color: #313131d0;
    color: white;
    font-family: "League Spartan", Helvetica, Arial, sans-serif;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    border-radius: 4px;
    box-shadow: 0 20px 80px 0 rgb(0 0 0 / 45%);
    overflow: hidden;
}
.isNotVisibleLeft {
    opacity: 0;
    /* transform: translateX(-100vw); */
    transition: all 1.0s 0.1s;
}
.isVisibleLeft {
    opacity: 1;
    /* transform: translateX(0vw); */
    transition: all 1.0s 0.1s;
}
.isNotVisibleRight {
    opacity: 0;
    /* transform: translateX(100vw); */
    transition: all 1.0s 0.1s;
}
.isVisibleRight {
    opacity: 1;
    /* transform: translateX(-0vw); */
    transition: all 1.0s 0.1s;
}
.isVisibleOnLoad {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 1.0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes fadein {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }


.project-details {
    display: flex;
    flex-flow: column wrap;
    width: 99%;
    padding: 20px;
    margin-left: auto;
    background-image: linear-gradient(to left, #31313100 0%, #0c0c0c 100%);
    z-index: 10;
}
.project-verbiage {
    width: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: auto;
    text-align: start;
}
.project-verbiage h1 {
    font-size: 50px;
    font-weight: 700;
}
.project-description {
    margin-bottom: 5%;
}

.project-verbiage button {
    min-width: 150px;
    max-width: 200px;
    height: 40px;
    padding: 0;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    background: #e0e0e0;
    margin-right: 5%;
    outline: 0;
    border: 0;
    cursor: pointer;
    line-height: 1;
    transition: transform 100ms, background 100ms;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.project-verbiage button:hover {
    transform: translateY(-2px);
}
.project-verbiage button:active {
    background-color: #FFCC66;
    transform: translateY(3px);
}
.project-verbiage a {
    color: #363636;
}

.project-verbiage a:hover {
    text-decoration: none;
}
.project-verbiage a:active {
    text-decoration: none;
}


.project-preview {
    display: flex;
    width: 1%;
    height: 100%;
    justify-content: flex-end;
}
.project-preview img {
    width: auto;
    height: 100%;
    border-radius: 4px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
}

@media(max-width: 801px) {
    .project-verbiage {
        width: 75%;
    }
    .project-verbiage button {
        min-width: 40%;
    }
    .isVisibleLeft {
        transform: translateX(0%);
    }
    .isVisibleRight {
        transform: translateX(0%);
    }
}

@media(max-width: 600px) {
    .project-verbiage {
        width: 85%;
    }
}

@media(max-width: 500px) {
    .project-ref-detect {
        margin-bottom: 50px;
    }
    .project-container {
        height: 550px;
    }
    .project-verbiage {
        width: 95%;
    }
}