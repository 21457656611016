.card {
    max-width: 400px;
}

.media {
    height: 0;
    padding-top: 56.25%;
}

.projects-header-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 100%;
    background: 
        linear-gradient(
            -20deg, 
            rgba(0,0,0,0.3),
            rgba(0, 40, 105, 0.8)
    );
    background-attachment: fixed;
    background-size: cover; 
}

.projects-header {
    color: aliceblue;
    font-size: 5em;
    font-family: "League Spartan", Helvetica, Arial, sans-serif;
    font-weight: 600;
    cursor: default;
}

.projects-content-box {
    display: flex;
    flex-flow: row wrap;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 0px;
    background: 
    linear-gradient(
        rgb(243, 243, 243),
        rgb(45, 51, 176, 2.26701)
    );
}

.indi-proj {
    margin: 10px auto;
    width: 300px;
    cursor: default;
}
.indi-proj h1 {
    font-size: 1.0em;
    margin: 5px auto;    
    font-weight: bold;
    text-align: center;
}
.indi-proj h2 {
    font-size: 0.9em;
    margin: 5px auto;
    font-style: italic;
    text-align: center;
}
.indi-proj p {
    font-size: 0.9em;
    margin: 20px auto;
    text-align: center;
}

.load-more-btn {
    font-family: "League Spartan", Helvetica, Arial, sans-serif;
    width: 30rem;
    height: 5rem;
    padding: 10px;
    border-radius: 10px;
    font-size: 2.2rem;
    font-weight: 700;
    background: #e0e0e0;
    margin-right: 5%;
    outline: 0;
    border: 0;
    cursor: pointer;
    line-height: 1;
    transition: transform 100ms, background 100ms;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin-left: auto;
    margin-right: auto;
}
.load-more-btn:hover {
    transform: translateY(-2px);
}
.load-more-btn:active {
    background-color: #FFCC66;
    transform: translateY(2px);
}

.load-more-p {
    font-family: "League Spartan", Helvetica, Arial, sans-serif;
    font-size: 2rem;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    color: white;
}

#more-to-come {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px 0px;    
    color: white;
    font-size: 1.5em;
    font-style: italic;
    cursor: default;
}


@media(max-width: 500px) {
    .projects-header {
        text-align: center;
    }
    .projects-content-box {
        padding: 5% 0;
    }
}