
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  max-width: 100vw;
  position: relative;
}
body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #e6e9f0bd;
}

.main-container {
  height: 100vh;
  width: 100%;
}

/* snow background and name/title */
.intro-box {
  display: flex;
  text-align: center;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
  background: 
    linear-gradient(
      /* 40deg, */
      rgba(0,0,0,0.3),
      rgba(0, 40, 105, 0.3)
    )
  ;
}
.intro-container {
  max-width: 100vw;
}
.intro-name {
  font-size: 3.5em;
}
.intro-title {
  font-size: 2.0em;
}
.main-body-box {
  height: auto;
}
.about-box {
  scroll-margin-top: 64.5px;
}
.projects-box {
  scroll-margin-top: 64.5px;
}
.skills-box {
  scroll-margin-top: 64.5px;
}
.contact-box {
  scroll-margin-top: 64.5px;
}

@media (max-width: 900px) {
  body {
    overflow-x: hidden;
    max-width: 100vw;
  }

}