.footer-container{
    border-top: 1px solid lightgrey;
    padding-top: 10px;
    /* background-color: rgb(45, 51, 176, 2.26701); */
    background-color: #1c1d25;
    color: aliceblue;
}
.footer-subtitle{
    display: flex;
    justify-content: center;
    font-family: "League Spartan", Helvetica, Arial, sans-serif;
    cursor: default;
}
.foot-contact-links{
    width: 100%;
    height: 100%;
    padding: 0px 0px 10px 0px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}
.foot-single-link {
    margin: 0px 25px;
}
.foot-icon{
    z-index: 2;
    -webkit-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    transition: all 400ms ease;
}
.foot-icon:hover{
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2); 
    -moz-transform: scale(1.2);
    transform: scale(1.2);
}
.github-icon2{
    width: 2.5em;
}

