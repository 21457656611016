

.skills-container {
    width: 100%;
    line-height: normal;
    font-size: 1em;
}
.skills-container ul {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, 150px);
    padding: 0px;
    margin-bottom: 20px;
    padding: 0 1em;
    list-style: none;
}
.skills-item {
    list-style-type: none;
    height: 3em;
}
.skills-item::before { 
    content: "";
    border-color: transparent #111;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    top: 35%;
    position: relative;
}
.skills-container li p {
    color: rgb(29, 29, 30);
    margin: 0px 20px;
}

@media (max-width: 900px) {
    .skills-container ul {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 50px;
    }
}