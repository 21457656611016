.about-container {
    width: 100%;
    min-height: calc(100vh - 65px);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
    background-color: rgb(243, 243, 243);
    cursor: default;
    font-family: "League Spartan", Helvetica, Arial, sans-serif;
}
.about-sub-container {
    width: 100%;
}
.about-content-container {
    max-width: 1281px;
    display: flex;
    flex-flow: row wrap-reverse;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
}
.profile-photo-box {
    display: flex;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    position: relative;
}
.profile-photo {
    display: inline-block;
    border-radius: 10px;
    z-index: 10;
}
.profile-photo-box::after {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 100%;
    height: 100%;
    border: 4px solid rgb(29, 29, 30);
    border-radius: 10px;
    content: '';
    z-index: 1;
}
.profile-photo img {
    border-radius: 10px;
}

.about-content {
    display: flex;
    flex-flow: row wrap;
    width: 55%;
    max-width: 800px;
    font-size: 1em;
    margin-left: 30px;
    margin-right: 20px;
    color: rgb(29, 29, 30);
    line-height: 2.5em;
    word-spacing: 0.25em;
}
.about-title {
    width: 100%;
    display: block;
}
.about-content h1  {
    font-weight: 700;
}
.about-content p  {
    font-weight: 600;
    font-size: 1.25em;
    margin: 0px 0px 10px 0px;
}
.please-note {
    font-style: italic;
    font-size: 0.8em;
}

/* Email, LinkedIn, Github section begins */
.contact-links {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    width: 25%;

    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.about-icon {
    z-index: 2;
    -webkit-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    transition: all 400ms ease;
}
.about-icon:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2); 
    -moz-transform: scale(1.2);
    transform: scale(1.2);
}
.email-link {
    display: flex;
    font-size: 17px;
    text-decoration: none;
}
.email-icon {
    width: 2.5em;
    margin: 0px 0px 0px 0px;
}
.email-text-link {
    color: grey;
}
.linkedin-link {
    display: flex;
    font-size: 17px;
    text-decoration: none;
}
.linkedin-icon {
    width: 3.5em;
    margin: 0px 0px 0px 0px;
}
.github-link {
    display: flex;
    font-size: 17px;
    text-decoration: none;
}
.github-icon {
    width: 2.5em;
    margin: 0px 0px 0px 0px;
}
.git-text-link {
    color: black;
}


@media(max-width: 961px) {
    .profile-photo-box {
        margin-bottom: 5%;
    }
    .about-content {
        width: 85%;
        font-size: 1em;
        margin-left: auto;
        margin-right: auto;
    }
    .about-title {
        text-align: center;
    }
    .contact-links {
        width: 100%;
    }
}